import React, { FunctionComponent, useContext } from 'react'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { SellerWidgetType } from '@bbx/common/types/UserFeed'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import Favorite from '@wh-components/icons/Favorite'
import Eye from '@wh-components/icons/Eye'
import { Text } from '@wh-components/core/Text/Text'
import ArrowRight from '@wh-components/icons/ArrowRight'
import Statistics from '@wh-components/icons/Statistics'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { hiddenScrollbar } from '@wh/common/chapter/components/hiddenScrollbar'
import { css, useTheme } from 'styled-components'

interface SellerWidgetWidgetProps {
    widget: SellerWidgetType
}

export const SellerWidget: FunctionComponent<SellerWidgetWidgetProps> = ({ widget }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const feedAreaBackgroundTransparent = advertisingState.pageModifications.backgroundTransparent?.includes('startpage-feed') ?? false
    const sitemutationBackgroundColor = advertisingState.pageModifications.backgroundColors?.['startpage-feed']
    const theme = useTheme()
    const taggingData = useFeedEmptyTaggingData()

    const border = sitemutationBackgroundColor ? 'none' : 'owl'

    const color = advertisingState.pageModifications.foregroundColors?.['startpage-standard-color']

    // test data for development
    // widget.sellerInfo.expiredDescription = '12 abgelaufene'
    // widget.sellerInfo.favoritesDescription = '86x gemerkt'
    // widget.sellerInfo.visitDescription = '35.555 Aufrufe'
    // widget.sellerInfo.statusDescription = '1.992 aktive'

    return (
        <ClientRoutingAnchorLink
            type="anchor"
            href="/iad/myprofile/myadverts"
            color={color ?? 'palette.verydarkgrey'}
            onClick={() => callActionEvent('startpage_seller_widget_click', taggingData)}
            underline="none"
            paddingHorizontal={{ tablet: 'm' }}
            css={css`
                background-color: ${feedAreaBackgroundTransparent
                    ? 'transparent'
                    : (sitemutationBackgroundColor ?? theme.colors.semantic.surface.DEFAULT)};
            `}
            testId={`feed-widget-${widget.type}`}
        >
            <Box
                display="flex"
                alignItems="center"
                border={border}
                borderRadius={{ tablet: 'm' }}
                paddingVertical={{ phone: 'xs' }}
                paddingRight={{ tablet: 's' }}
                paddingLeft={{ phone: 'm', tablet: 's' }}
                overflowX="auto"
                overflowY="hidden"
                css={css`
                    background-color: ${!sitemutationBackgroundColor && theme.colors.palette.polarbear};
                    ${hiddenScrollbar}
                `}
            >
                <Box
                    display="flex"
                    color={color}
                    fontSize="s"
                    flexDirection={{ phone: 'column', desktop: 'row' }}
                    flexWrap="wrap"
                    alignItems={{ desktop: 'center' }}
                    columnGap={{ phone: 'm', tablet: 'xl' }}
                    maxHeight={{ phone: '48px', tablet: 'auto' }}
                >
                    <Box display="flex" gap="s" alignItems="center">
                        <Box display={{ phone: 'none', tablet: 'flex' }} alignItems="center">
                            <Statistics color={color ?? 'palette.koala'} size="large" />
                        </Box>
                        <Box display="flex" flexDirection={{ phone: 'column', desktop: 'row' }} gap={{ desktop: 's' }}>
                            <Text fontWeight="bold">Meine Anzeigen</Text>
                            <Text>Quick-Statistik</Text>
                        </Box>
                    </Box>

                    {widget.sellerInfo.statusDescription && (
                        <Box display="flex" alignItems="center">
                            <Circle
                                backgroundColor={widget.sellerInfo.status === 'INACTIVE' ? 'palette.raccoon' : 'palette.signal.green'}
                            />
                            <Text color={color ?? (widget.sellerInfo.status === 'INACTIVE' ? 'palette.raccoon' : 'palette.signal.green')}>
                                {widget.sellerInfo.statusDescription}
                            </Text>
                        </Box>
                    )}
                    {widget.sellerInfo.visitDescription && (
                        <Box display="flex" alignItems="center" color={color ?? 'palette.raccoon'}>
                            <Eye color={color ?? 'palette.raccoon'} marginRight="xs" />
                            {widget.sellerInfo.visitDescription}
                        </Box>
                    )}
                    {widget.sellerInfo.favoritesDescription && (
                        <Box display="flex" alignItems="center" color={color ?? 'palette.raccoon'}>
                            <Favorite color={color ?? 'palette.raccoon'} marginRight="xs" />
                            {widget.sellerInfo.favoritesDescription}
                        </Box>
                    )}
                    {widget.sellerInfo.expiredDescription && (
                        <Box display="flex" alignItems="center">
                            <Circle backgroundColor="palette.signal.orange" />
                            <Text color={color ?? 'palette.signal.orange'}>{widget.sellerInfo.expiredDescription}</Text>
                        </Box>
                    )}
                </Box>

                <ArrowRight color={color ?? 'palette.koala'} size="small" marginLeft="auto" />
            </Box>
        </ClientRoutingAnchorLink>
    )
}

export const Circle: FunctionComponent<BoxProps> = (props) => {
    return (
        <Box
            display="inline-block"
            width={{ phone: '6px', tablet: '8px' }}
            height={{ phone: '6px', tablet: '8px' }}
            borderRadius="13px"
            marginRight="5px"
            marginLeft={{ phone: '2px', desktop: 0 }}
            {...props}
        >
            &nbsp;
        </Box>
    )
}
