import { useStartPageDigitalAdvertising } from '@bbx/common/digital-advertising/hooks/useStartPageDigitalAdvertising'
import { SellerWidgetType, TeaserWidgetType, UserFeed } from '@bbx/common/types/UserFeed'
import { useEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { AdIdSearch } from '@bbx/search-journey/sub-domains/start-page/components/AdIdSearch'
import { DemBaertSeineInfoBox } from '@bbx/search-journey/sub-domains/start-page/components/DemBaertSeineInfoBox'
import { Teaser } from '@bbx/search-journey/sub-domains/start-page/components/Banner/Teaser'
import { WidgetDiscriminator } from '@bbx/search-journey/sub-domains/start-page/components/WidgetDiscriminator'
import { Box } from '@wh-components/core/Box/Box'
import { DmpStateProvider } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { isProduction } from '@wh/common/chapter/lib/config/runtimeConfig'
import { callPageView } from '@wh/common/chapter/lib/tagging/tagging'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import React, { Fragment, FunctionComponent, useContext, useEffect } from 'react'
import { useCookieState } from '@wh/common/chapter/hooks/useCookieState'
import dayjs from 'dayjs'
import { SellerWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/SellerWidget'
import { useTheme } from 'styled-components'

interface StartPageContainerProps {
    userFeed?: UserFeed
    nrOfAdverts?: number
    isJobsWidgetHidden: boolean
}

export const StartPageContainer: FunctionComponent<StartPageContainerProps> = ({ userFeed, nrOfAdverts, isJobsWidgetHidden }) => {
    const { trackCustomDmpEvent } = useStartPageDigitalAdvertising()
    const taggingData = useEmptyTaggingData()
    const theme = useTheme()

    useEffect(() => {
        callPageView('homepage', { taggingData: taggingData })
    }, [taggingData])

    const advertisingState = useContext(AdvertisingStateContext)
    const teaserAreaBackgroundTransparent = advertisingState.pageModifications.backgroundTransparent?.includes('startpage-teaser') ?? false
    const backgroundColorTeaser = teaserAreaBackgroundTransparent
        ? 'transparent'
        : (advertisingState.pageModifications.backgroundColors?.['startpage-teaser'] ?? theme.colors.semantic.surface.DEFAULT)
    const feedAreaBackgroundTransparent = advertisingState.pageModifications.backgroundTransparent?.includes('startpage-feed') ?? false
    const backgroundColorFeed = feedAreaBackgroundTransparent
        ? 'transparent'
        : (advertisingState.pageModifications.backgroundColors?.['startpage-feed'] ?? theme.colors.semantic.surface.DEFAULT)
    const bodyBorder = advertisingState.pageModifications.borders?.['startpage-body']
    const color = advertisingState.pageModifications.foregroundColors?.['startpage-standard-color']

    const teaserWidget = userFeed?.widgets.find((widget) => widget.type === 'TEASER')
    const sellerWidget = userFeed?.widgets.find((widget) => widget.type === 'SELLER')
    const [isJobsWidgetCookieHidden, setJobsWidgetHidden] = useCookieState<'true' | 'false'>(
        'bbxHideJobsStartpageWidget',
        isJobsWidgetHidden ? 'true' : 'false',
    )

    return (
        <DmpStateProvider trackCustomDmpEvent={trackCustomDmpEvent}>
            <Box
                position="relative"
                display="flex"
                flexDirection="column"
                paddingBottom={{ phone: 'm', tablet: 0 }}
                border={{ tablet: bodyBorder ? '1px solid' : undefined }}
                borderLeft={{ tablet: undefined }}
                borderColor={{ tablet: bodyBorder }}
            >
                <Box padding={{ tablet: 'm' }} background={backgroundColorTeaser}>
                    {teaserWidget && <Teaser nrOfAdverts={nrOfAdverts} teaserWidget={teaserWidget as TeaserWidgetType} />}
                    {!isProduction() && <DemBaertSeineInfoBox />}
                    {!isProduction() && <AdIdSearch />}
                </Box>
                {sellerWidget && <SellerWidget widget={sellerWidget as SellerWidgetType} />}
                <Box
                    background={backgroundColorFeed}
                    color={color}
                    padding={{ tablet: 'm' }}
                    paddingLeft={{ phone: 'm' }}
                    paddingTop={{ phone: 'm' }}
                >
                    {userFeed?.widgets?.map((widget) => {
                        return (
                            <Fragment key={widget.type}>
                                <WidgetDiscriminator
                                    widget={widget}
                                    isJobsWidgetHidden={isJobsWidgetCookieHidden === 'true'}
                                    onHideJobsWidget={() =>
                                        setJobsWidgetHidden(
                                            'true',
                                            dayjs()
                                                .add(dayjs.duration({ months: 1 }))
                                                .toDate(),
                                        )
                                    }
                                />
                            </Fragment>
                        )
                    })}
                </Box>
            </Box>
        </DmpStateProvider>
    )
}
