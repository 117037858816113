import React, { FunctionComponent, ReactElement, useContext } from 'react'
import { FeedWidgetWrapper } from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { css, useTheme } from 'styled-components'
import VerticalMarketplaceIcon from '@wh-components/icons/VerticalMarketplace'
import VerticalEstateIcon from '@wh-components/icons/VerticalEstate'
import VerticalCarIcon from '@wh-components/icons/VerticalCar'
import VerticalJobsIcon from '@wh-components/icons/VerticalJobs'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { staticRelativeCanonicals } from '@wh/common/chapter/hocs/staticRelativeCanonicals'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { BaseWidget } from '@bbx/common/types/UserFeed'

interface Vertical {
    title: string
    description: string
    href: string
    icon: ReactElement
}

interface Props {
    widget: BaseWidget
}

export const VerticalsWidget: FunctionComponent<Props> = ({ widget }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const theme = useTheme()
    const topFontColor = advertisingState.pageModifications.foregroundColors?.['startpage-verticals-widget-top'] ?? 'palette.primary.main'
    const topBackground =
        advertisingState.pageModifications.backgroundColors?.['startpage-verticals-widget-top'] ?? theme.colors.semantic.surface.DEFAULT
    const bottomFontColor =
        advertisingState.pageModifications.foregroundColors?.['startpage-verticals-widget-bottom'] ?? 'semantic.foreground.white'
    const bottomBackground =
        advertisingState.pageModifications.backgroundColors?.['startpage-verticals-widget-bottom'] ?? 'palette.primary.main'
    const borderColor = advertisingState.pageModifications.borders?.['startpage-verticals-widget'] ?? 'palette.primary.main'
    const taggingData = useFeedEmptyTaggingData()

    return (
        <FeedWidgetWrapper type={widget.type}>
            <Box
                display={{ phone: 'grid', tablet: 'flex' }}
                paddingRight={{ phone: 'm', tablet: 0 }}
                css={css`
                    grid-template-columns: 1fr 1fr;
                    grid-gap: ${(p) => p.theme.space.s}px;
                    gap: ${(p) => p.theme.space.s}px;
                `}
            >
                {VERTICALS.map((vertical) => {
                    return (
                        <ClientRoutingAnchorLink
                            type="anchor"
                            href={vertical.href}
                            width="100%"
                            key={vertical.title}
                            color={topFontColor}
                            onClick={() =>
                                callActionEvent('startpage_vertical_click', taggingData, {
                                    vertical_label: vertical.title,
                                })
                            }
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                flexBasis={{ phone: '49%', tablet: '25%' }}
                                height={{ phone: 52, tablet: 125 }}
                                border="1px solid"
                                borderColor={borderColor}
                                borderRadius="m"
                                overflow="hidden"
                            >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    backgroundColor={topBackground}
                                    fontWeight="bold"
                                    flex={1}
                                >
                                    {React.cloneElement(vertical.icon, { color: topFontColor })}
                                    <Text marginTop={{ tablet: 's' }}>{vertical.title}</Text>
                                </Box>
                                <Box
                                    display={{ phone: 'none', tablet: 'flex' }}
                                    color={bottomFontColor}
                                    backgroundColor={bottomBackground}
                                    padding="sm"
                                    fontSize="s"
                                    justifyContent="center"
                                    alignItems="center"
                                    minHeight={{ tablet: 78, desktop: 55 }}
                                    css={css`
                                        outline: 0;
                                    `}
                                >
                                    {vertical.description}
                                </Box>
                            </Box>
                        </ClientRoutingAnchorLink>
                    )
                })}
            </Box>
        </FeedWidgetWrapper>
    )
}

const VERTICALS: Vertical[] = [
    {
        title: 'Marktplatz',
        description: 'Alles finden - am größten digitalen Marktplatz Österreichs',
        icon: <VerticalMarketplaceIcon size={{ phone: 30, desktop: 40 }} marginRight="s" />,
        href: staticRelativeCanonicals.BapHome,
    },
    {
        title: 'Immobilien',
        description: 'Entdecke dein neues Zuhause auf willhaben',
        icon: <VerticalEstateIcon size={{ phone: 30, desktop: 40 }} marginRight="s" />,
        href: staticRelativeCanonicals.EstateHome,
    },
    {
        title: 'Auto & Motor',
        description: 'Deine Autos und Motorräder in Österreich',
        icon: <VerticalCarIcon size={{ phone: 30, desktop: 40 }} marginRight="s" />,
        href: staticRelativeCanonicals.MotorHome,
    },
    {
        title: 'Jobs',
        description: 'Du suchst einen neuen Job? Wir haben!',
        icon: <VerticalJobsIcon size={{ phone: 30, desktop: 40 }} marginRight="s" />,
        href: staticRelativeCanonicals.JobsHome,
    },
]
