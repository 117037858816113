import React, { useEffect, useState } from 'react'
import { fetcher } from '@wh/common/chapter/api/fetcher'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { IconButton } from '@wh-components/core/Button/Button'
import { Tooltip } from '@wh-components/core/Tooltip/Tooltip'
import CopyLink from '@wh-components/icons/CopyLink'
import RotateRight from '@wh-components/icons/RotateRight'
import EyeDisabled from '@wh-components/icons/EyeDisabled'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { toast } from '@wh-components/core/Toast/Toast'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { ApiErrorAlert } from '@wh/common/chapter/components/Alerts/ApiErrorAlert'

type iadEnvDataResponse = {
    iadVersion: string
    gitlabPipeline: string
    buildTime: string
    deployTime: string
    upTime: string
}

const initialEnvData = {
    iadVersion: 'no data available',
    gitlabPipeline: 'no data available',
    buildTime: 'no data available',
    deployTime: 'no data available',
    upTime: 'no data available',
}

const apiUrl = '/iad/internal/debug/demBaertSeinEndpunkt'

export const DemBaertSeineInfoBox = () => {
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)
    const [isBoxVisible, setIsBoxVisible] = useState<boolean>(false)
    const [envData, setEnvData] = useState<iadEnvDataResponse>(initialEnvData)

    async function fetchIadInfoAndSetState() {
        setIsButtonDisabled(true)
        try {
            const data = await fetcher<iadEnvDataResponse>(apiUrl)
            setEnvData(data)
        } catch (error) {
            const apiError = wrapInApiErrorIfNecessary(error)
            toast(<ApiErrorAlert title="Dem Bärt sein Toast" error={apiError.errorResponse} />, { type: 'error' })
        } finally {
            setIsButtonDisabled(false)
        }
    }

    useEffect(() => {
        if (isBoxVisible) {
            void fetchIadInfoAndSetState()
        }
    }, [isBoxVisible])

    if (!isBoxVisible) {
        return null
    }

    return (
        <Box
            display={{ tablet: 'none', phone: 'none', desktop: 'flex' }}
            flexDirection="column"
            position="fixed"
            gap="xs"
            left="985px"
            top="20px"
            width="300px"
            zIndex="popover"
            border="2px solid"
            borderColor="palette.primary.main"
            borderRadius="m"
            backgroundColor="semantic.surface"
            padding="s"
        >
            <Heading level={3} text="Dem Bärt seine Infobox" marginBottom="-5px" />
            <Text fontSize="xs">If you can read this you are on a test environment</Text>
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" paddingRight="s" gap="xs">
                <Text>
                    <strong>iAd Version:</strong>
                </Text>
                <Text style={{ whiteSpace: 'nowrap' }}>{envData.iadVersion}</Text>
                <Text style={{ whiteSpace: 'nowrap' }}>
                    <strong>GitLab pipeline:</strong>
                </Text>
                {envData.gitlabPipeline.toString() === 'no data available' ? (
                    <Text>{envData.gitlabPipeline}</Text>
                ) : (
                    <ServerRoutingAnchorLink type="anchor" href={envData.gitlabPipeline} target="_blank" testId="infoboxGitlabLink">
                        <CopyLink color="palette.main" size="xsmall" />
                        external link
                    </ServerRoutingAnchorLink>
                )}
                <Text>
                    <strong>Build time:</strong>
                </Text>
                <Text>{envData.buildTime}</Text>
                <Text>
                    <strong>Deploy time:</strong>
                </Text>
                <Text>{envData.deployTime}</Text>
                <Text>
                    <strong>Uptime:</strong>
                </Text>
                <Text>{envData.upTime}</Text>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Tooltip content="Fetch data from API">
                    <IconButton
                        size="xsmall"
                        disabled={isButtonDisabled}
                        Icon={RotateRight}
                        onClick={() => fetchIadInfoAndSetState()}
                        testId="infoboxFetchDataButton"
                    />
                </Tooltip>
                <Text hidden={!isButtonDisabled}>Fetching data from API...</Text>
                <Tooltip content="Hide this box until next page reload">
                    <IconButton size="xsmall" Icon={EyeDisabled} onClick={() => setIsBoxVisible(false)} testId="infoboxHideButton" />
                </Tooltip>
            </Box>
        </Box>
    )
}
